import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: true,
};

const changeStateSlice = createSlice({
  name: 'changeState',
  initialState,
  reducers: {
    SetSidebar: (state, action) => {
      console.log("set payload", action);
      if (action.payload) {
        console.log("state", state["sidebarShow"]);
        return { ...state, ...action.payload };
      }
    },
  },
});

// Export the action creators generated by createSlice
export const { SetSidebar } = changeStateSlice.actions;

// Export the reducer
export default changeStateSlice.reducer;
